.button {
  display: inline-block;
  height: 10%;
  .selected-link {
    background-color: #332B34;
    width: 15%;
    position: fixed;
    border: 1px solid transparent;
    border-radius: 0 11px 11px 0;
    @media screen and (max-width: 768px) {
      width: 100%;
      position: initial;
      border: none;
      border-radius: 0;
    }
    span {
      color: #fff;
    }
  }
  .nav-button {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
    @media screen and (max-width: 768px) {
      display: flex;
      align-items: flex-start;
    }
    .image-nav-button {
      display: flex;
      align-items: center;
      justify-content: left;
      img {
				object-position: 50% 50%;
				width: 15%;
				margin:auto;
        // margin-right: 15px;
        @media screen and (max-width: 768px) {
          width: 25px;
        }
      }
    }
    p {
      text-align: left;
      margin: 0;
      color: #fff;
      font-weight: bold;
      font-size: 1em;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

}
