.container-navbar {
  background-color: #224770;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 14%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  position: fixed;
  @media screen and (max-width: 768px){
    width: 100%;
    display: block;
    height: 15%;
    position: relative;
  }
  .logo-navbar {
    height: 20%;
    padding: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
      font-size: 9vw;
      padding: 10px;
    }
    img {
      width: 70%;
      @media screen and (max-width: 768px) {
        width: 20%  
      }
    }
  }

  .button-collapsed{ 
    display: flex;
    position: absolute;
    right: 0;
  }

  .container-routes {
    height: 75%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    @media screen and (max-width: 768px) {
      height: 80%;
      width: 100%;
    }
  }
}