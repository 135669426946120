.button-selectable-table {
  background: #505050;
  padding: 10px;
  width: 120px;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
}
