.container-header{
    .left{
        display: inline-block;
        text-align: left;
        width: 40%;
				margin-top: 20px;
				margin-bottom: 10px;
        .title{
            display: inline-block;
        }
        .video{
            display: inline-flex;
            flex-direction: column;
            width: 5%;    
            text-align: center;
            padding-left: 20px;
            font-size: 8px;
            width: auto;
            p{
                font-size: 8px;
                text-align: center;
            }   
        }
    }
    .right{
        display: flex;
        align-items: stretch;
        justify-content: flex-end;
        width: 100%;
        height: 35px;
        margin-top: 20px;
        margin-bottom: 10px;
        button{
            height: 100%;
        }
    }
}
.button-header{
	margin: 0 10px !important;
}