$color-background-caja: #f8f8f8;
$color-background: #3b4c4b;
$color-resalto: #224770;

* {
  font-family: "Lato", sans-serif, Arial;
  box-sizing: border-box;
  // color: $color-background;
  margin: 0;
  padding: 0;
}
.swal-modal .swal-text {
	text-align: center;
}
html {
  overflow-y: scroll;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  // width: 90%;
  background-color: #fff;
  border: 1px solid transparent;
  padding: 30px;
  overflow-y: auto;
  height: auto;
  max-height: 90%;
  min-width: 600px;
  // width: 90%;
  border-radius: 10px;
  &:focus {
    outline: 0;
  }

  @media only screen and (max-width: 600px) {
    width:95%;
    min-width: unset;
    padding: 10px;

  }
  
}
.MuiInputBase-input{
  padding:0
}
.formControl {
  min-width: 120;
}

.flexContainer {
  background-color: $color-background-caja;
  color: #000000;
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $color-background-caja;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-background;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-background;
}


textarea {
  border-radius: 5px;
	padding:5px;
	border-color: #DEDEDE;
  // resize: none;
}