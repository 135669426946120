.container-filter{
    width: 100%;
    height: 10%;
    border: 1px solid #337ab7;
    border-radius: 4px ;
    background: #fff;
    .header{
        padding: 10px;
        width: 100%;
        background: #337ab7;
        border-color: #337ab7;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        p{
            margin: 0;
            color: #fff;
        }
    }
    .container-fields{
        width: 100%;
        padding: 10px;
        display: flex;
        .label-field{
            display: block;
        }
    }
}
.filtros-base-table {
  // background-color: $color-background;
  padding: 15px;
  margin-bottom: 10px;
  .row-filter{
    display: flex;
    justify-content: left;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}
